<template>
  <section class="coovally_m">
    <div class="dialogcontainer">
      <div class="contactus">
        <el-dialog
          class="project_trash_dialog"
          :visible.sync="ContactUsVisible"
          width="288px"
          :close-on-click-modal="false"
          :show-close="false"
          style="margin-top: 15vh; padding: 0"
        >
          <div class="title">
            <span class="title_text">暂不支持手机端访问</span>
            <span class="title_text">请前往PC网页端进行注册体验</span>
          </div>
          <div class="dialog_button">
            <div class="close" @click="ContactUsVisible = false">我知道了</div>
          </div>
        </el-dialog>
      </div>
    </div>

    <!-- <div class="active" v-show="acticityShow">
      <img
        src="../../../assets/zh/images-mobile/shouye_huodong@2x.png"
        alt=""
        @click="RouteActicity()"
      />
      <span
        style="color: white"
        class="iconfont iconToast_closed close"
        @click="acticityShow = false"
      ></span>
    </div> -->

    <div class="buttons">
      <div class="button">
        <el-button class="checked"      style="width: 68px" >{{
          $t("PC.Product.CooVallyButton.deaa52")
        }}</el-button>
        <el-button
          class="unchecked"
          @click="RoutePush('/CooVally')"
          style="width: 68px"
          >{{ $t("PC.Product.CooVallyButton.2446d2") }}</el-button
        >
        <el-button class="unchecked" @click="RoutePush('/AIShopNew')">{{
          $t("PC.Product.CooVallyButton.fad28a")
        }}</el-button>
      </div>
    </div>

    <div class="firstItem">
      <div class="title" v-show="$t('locale') == 'zh'">
        <span>{{ $t("PC.Product.CooVally.67ecea") }}</span>
      </div>

      <div class="text" v-show="$t('locale') == 'zh'">
        <span>{{ $t("PC.Product.CooVally.8887a5") }}</span>
      </div>

      <div class="title_en" v-show="$t('locale') == 'en'">
        <span>{{ $t("PC.Product.CooVally.67ecea") }}</span>
      </div>

      <div class="text_en" v-show="$t('locale') == 'en'">
        <span>{{ $t("PC.Product.CooVally.8887a5") }}</span>
      </div>
    </div>

    <div class="mainImg" v-show="$t('locale') == 'zh'">
      <img
        src="../../../assets/images/CooVally/shouye_jiemian@2x.png"
        style="width: 100%; height: 100%"
        alt=""
      />
    </div>

    <div class="mainImg_en" v-show="$t('locale') == 'en'">
      <img
        src="../../../assets/images/CooVally/shouye_jiemian@2x.png"
        style="width: 100%; height: 100%"
        alt=""
      />
    </div>

    <div class="reason">
      <div class="text" v-show="$t('locale') == 'zh'">
        <p>{{ $t("PC.Product.CooVally.a7ad3a") }}</p>
        <span class="title">{{ $t("PC.Product.CooVally.7c3d01") }} </span>
        <span class="title" style="margin-top: 0px; padding-bottom: 18px">
          {{ $t("PC.Product.CooVally.de1f46") }}</span
        >
      </div>

      <div class="text_en" v-show="$t('locale') == 'en'">
        <p>{{ $t("PC.Product.CooVally.a7ad3a") }}</p>
        <span class="title" style="padding-bottom: 18px"
          >{{ $t("PC.Product.CooVally.7c3den") }}
        </span>
      </div>

      <div class="reasons">
        <div class="reason1">
          <div class="reason1_text">
            <div class="icon">
              <img
                src="../../../assets/images/CooVally/shouye_liucheng@2x.png"
                alt=""
              />
            </div>

            <div class="reasons_title" v-show="$t('locale') == 'zh'">
              <span>{{ $t("PC.Product.CooVally.8db978") }}</span>
            </div>

            <div class="desc" v-show="$t('locale') == 'zh'">
              <span>{{ $t("PC.Product.CooVally.3cadbc") }}</span>
            </div>

            <div class="reasons_title_en" v-show="$t('locale') == 'en'">
              <span>{{ $t("PC.Product.CooVally.8db978") }}</span>
            </div>

            <div class="desc_en" v-show="$t('locale') == 'en'">
              <span>{{ $t("PC.Product.CooVally.3cadbc") }}</span>
            </div>
          </div>
        </div>

        <div class="reason2">
          <div class="reason2_text">
            <div class="icon">
              <img
                src="../../../assets/images/CooVally/shouye_sousuo@2x.png"
                alt=""
              />
            </div>
            <div class="reasons_title" v-show="$t('locale') == 'zh'">
              <span>{{ $t("PC.Product.CooVally.a7eb83") }}</span>
            </div>

            <div class="reasons_title_en" v-show="$t('locale') == 'en'">
              <span>{{ $t("PC.Product.CooVally.a7eb83") }}</span>
            </div>
          </div>
        </div>

        <div class="reason3">
          <div class="reason3_text">
            <div class="icon">
              <img
                src="../../../assets/images/CooVally/shouye_moxing@2x.png"
                alt=""
              />
            </div>
            <div class="reasons_title" v-show="$t('locale') == 'zh'">
              <span>{{ $t("PC.Product.CooVally.dd4a7a") }}</span>
            </div>

            <div class="reasons_title_en" v-show="$t('locale') == 'en'">
              <span>{{ $t("PC.Product.CooVally.dd4a7a") }}</span>
            </div>
          </div>
        </div>

        <div class="reason4">
          <div class="reason4_text">
            <div class="icon">
              <img
                src="../../../assets/images/CooVally/shouye_zhuanhuan@2x.png"
                alt=""
              />
            </div>

            <div class="reasons_title" v-show="$t('locale') == 'zh'">
              <span>{{ $t("PC.Product.CooVally.041dbb") }}</span>
            </div>

            <div class="reasons_title_en" v-show="$t('locale') == 'en'">
              <span>{{ $t("PC.Product.CooVally.041dbb") }}</span>
            </div>
          </div>
        </div>

        <div class="reason5">
          <div class="reason5_text">
            <div class="icon">
              <img
                src="../../../assets/images/CooVally/shouye_biaozhu@2x.png"
                alt=""
              />
            </div>

            <div class="reasons_title" v-show="$t('locale') == 'zh'">
              <span>{{ $t("PC.Product.CooVally.9d3e7d") }}</span>
            </div>

            <div class="reasons_title_en" v-show="$t('locale') == 'en'">
              <span>{{ $t("PC.Product.CooVally.9d3e7d") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modular">
      <div class="title">
        <p>{{ $t("PC.Product.CooVally.61b160") }}</p>
      </div>
      <div class="desc">
        <p>{{ $t("PC.Product.CooVally.13b263") }}</p>
      </div>

      <div class="boxWrap">
        <div class="caseBox" v-for="(item, index) in caseBox" :key="index">
          <div class="iconWrap">
            <img :src="item.src" alt="" />
          </div>
          <p>{{ item.title }}</p>
          <p>{{ item.content }}</p>
        </div>
      </div>
    </div>

    <div class="layout">
      <div class="wordWrap">
        <p>{{ $t("PC.Product.CooVally.248976") }}</p>
        <el-button
          class="button"
          @click="GoCooVally()"
          v-show="$t('locale') == 'zh'"
          >{{ $t("PC.Product.CooVallyButton.ed4a49") }}</el-button
        >

        <el-button
          class="button_en"
          @click="GoCooVally()"
          v-show="$t('locale') == 'en'"
          >{{ $t("PC.Product.CooVallyButton.ed4a49") }}</el-button
        >
      </div>

      <img src="../../../assets/images/CooVally/shouye_bg4@2x.png" alt="" />
    </div>

    <FooterB></FooterB>
  </section>
</template>

<script>
import FooterB from "../components/FooterB";
export default {
  components: {
    FooterB,
  },
  data() {
    return {
      acticityShow: true,
      ContactUsVisible: false,
      caseBox: [
        {
          src: require("/src/assets/images/CooVally/shouye_computer@2x.png"),
          title: this.$t("PC.Product.CooVally.ec3153"),
          content: this.$t("PC.Product.CooVally.7614d9"),
        },
        {
          src: require("/src/assets/images/CooVally/icon_gongshi@2x.png"),
          title: this.$t("PC.Product.CooVally.1a8067"),
          content: this.$t("PC.Product.CooVally.1d3cbc"),
        },
        {
          src: require("/src/assets/images/CooVally/icon_jingli@2x.png"),
          title: this.$t("PC.Product.CooVally.4566bf"),
          content: this.$t("PC.Product.CooVally.ed6684"),
        },
        {
          src: require("/src/assets/images/CooVally/shouye_ai@2x.png"),
          title: this.$t("PC.Product.CooVally.90cff7"),
          content: this.$t("PC.Product.CooVally.95f9a7"),
        },
        {
          src: require("/src/assets/images/CooVally/shouye_ruanjian@2x.png"),
          title: this.$t("PC.Product.CooVally.5d0980"),
          content: this.$t("PC.Product.CooVally.4219d5"),
        },
        {
          src: require("/src/assets/images/CooVally/shouye_yingjian@2x.png"),
          title: this.$t("PC.Product.CooVally.a6a58e"),
          content: this.$t("PC.Product.CooVally.62676d"),
        },
        {
          src: require("/src/assets/images/CooVally/shouye_ziyou@2x.png"),
          title: this.$t("PC.Product.CooVally.dcc95c"),
          content: this.$t("PC.Product.CooVally.95f74f"),
        },
      ],
    };
  },

  methods: {
    RoutePush(url) {
      this.$router.push({
        path: url,
      });
    },
    RouteActicity() {
      let routeData = this.$router.resolve({ path: "/Acticity" });
      window.open(routeData.href, "_blank");
    },

    GoCooVally() {
      this.ContactUsVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.coovally_m {
  margin-top: 48px;
  word-break: break-word !important;
  width: 100%;

  .dialogcontainer {
    .contactus {
      /deep/ .el-dialog__header {
        padding: 0;
      }

      /deep/ .el-dialog__body {
        padding: 0;
        margin-top: -10px;
      }

      .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
        text-align: center;
        margin-top: 25px;
        padding-bottom: 10px;
      }
      /deep/ .dialog_button {
        padding-top: 14px;
        .close {
          width: 100%;
          height: 50px;
          padding-top: 13px;
          text-align: center;
          border-top: 1px solid #eaeaf0;
          // background: red;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2b2c3d;
          line-height: 22px;
        }
        .close:hover {
          color: #2b2c3dcc;
        }
      }
    }
  }

  .active {
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
    }

    .close {
      z-index: 99;
      position: absolute;
      right: 1vh;
      top: 8%;
      // background: red;
      // widows: 20px;
      height: 20px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -60px;
    // z-index: 1;

    .button {
      display: flex;
      justify-content: space-between;
      width: 260px;
      padding-top: 12px;

      .checked {
        width: 83px;
        height: 30px;
        background-color: #4568ee;
        color: #fafcff;
        border-radius: 4px;
        font-weight: 600;
        border: none;
        line-height: 30px;
        padding: 0;
        font-size: 14px;
        z-index: 0;
      }
      .unchecked {
        z-index: 0;

        width: 83px;
        height: 30px;
        background-color: #4568ee1a;
        color: #4568ee;
        border-radius: 4px;
        font-weight: 600;
        border: none;
        line-height: 30px;
        padding: 0;
        font-size: 14px;
      }
    }
  }

  .firstItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 238px;
    background-image: url("../../../assets/zh/images-mobile/bg1@3x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // position: relative;
    .title {
      margin-top: 79px;
      width: 350px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2d3242;
      line-height: 22px;
      margin-bottom: 6px;
    }
    .text {
      width: 270px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2d3242;
      line-height: 20px;
    }

    .title_en {
      margin-top: 79px;
      width: 310px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2d3242;
      line-height: 22px;
    }
    .text_en {
      width: 270px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2d3242;
      line-height: 20px;
    }
  }
  .mainImg {
    position: relative;
    margin: 0 auto;
    margin-top: -135px;
    width: 371.4px;
    height: 287.5px;
  }

  .mainImg_en {
    position: relative;
    margin: 0 auto;
    margin-top: -80px;
    width: 371.4px;
    height: 287.5px;
  }

  .reason {
    // position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // height: 750px;
    width: 100%;
    margin-top: -25px;
    .text {
      p {
        width: 335px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #1f232e;
        line-height: 22px;
        // padding-top: 46px;
      }

      .title {
        display: block;
        width: 335px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #51565d;
        line-height: 18px;
        margin-top: 6px;
      }
    }

    .text_en {
      p {
        width: 335px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #1f232e;
        line-height: 22px;
        // padding-top: 46px;
      }

      .title {
        display: block;
        width: 335px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #51565d;
        line-height: 18px;
        margin-top: 6px;
      }
    }

    .reasons {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: left;
      width: 335px;

      img {
        width: 30px;
        height: 30px;
      }

      .reason1 {
        width: 335px;
        height: 140px;
        background: #fafcff;
        background-image: url("../../../assets/zh/images-mobile/bg3@3x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 12px;
        .reason1_text {
          position: relative;
          top: 16px;
          left: 16px;
          .icon {
            position: absolute;
          }
          .reasons_title {
            position: absolute;
            top: 45px;
            width: 310px;
            height: 19px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 18px;
          }
          .desc {
            position: absolute;
            text-align: left;
            top: 70px;
            width: 300px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
          }

          .reasons_title_en {
            position: absolute;
            top: 40px;
            width: 310px;
            height: 19px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 18px;
          }
          .desc_en {
            position: absolute;
            text-align: left;
            top: 80px;
            width: 300px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
          }
        }
      }

      .reason2 {
        width: 335px;
        height: 140px;
        background: #fafcff;
        background-image: url("../../../assets/zh/images-mobile/bg4@3x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 12px;
        .reason2_text {
          position: relative;
          top: 16px;
          left: 16px;
          text-align: left;
          .icon {
            position: absolute;
          }
          .reasons_title {
            position: absolute;
            top: 45px;
            width: 210px;
            height: 44px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
          }
          .reasons_title_en {
            position: absolute;
            top: 45px;
            width: 310px;
            height: 44px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
          }
        }
      }

      .reason3 {
        width: 335px;
        height: 140px;
        background: #fafcff;
        background-image: url("../../../assets/zh/images-mobile/bg5@3x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 12px;
        .reason3_text {
          position: relative;
          top: 16px;
          left: 16px;
          text-align: left;
          .icon {
            position: absolute;
          }
          .reasons_title {
            position: absolute;
            top: 45px;
            width: 214px;
            height: 44px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
          }

          .reasons_title_en {
            position: absolute;
            top: 45px;
            width: 314px;
            height: 44px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
          }
        }
      }

      .reason4 {
        width: 335px;
        height: 140px;
        background: #fafcff;
        background-image: url("../../../assets/zh/images-mobile/bg6@3x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 12px;
        .reason4_text {
          position: relative;
          top: 16px;
          left: 16px;
          text-align: left;
          .icon {
            position: absolute;
          }
          .reasons_title {
            position: absolute;
            top: 45px;
            width: 216px;
            height: 44px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
          }

          .reasons_title_en {
            position: absolute;
            top: 45px;
            width: 316px;
            height: 44px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
          }
        }
      }

      .reason5 {
        width: 335px;
        height: 140px;
        background: #fafcff;
        background-image: url("../../../assets/zh/images-mobile/bg7@3x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        .reason5_text {
          position: relative;
          top: 16px;
          left: 16px;
          text-align: left;
          .icon {
            position: absolute;
          }
          .reasons_title {
            position: absolute;
            top: 45px;
            width: 216px;
            height: 44px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
          }
          .reasons_title_en {
            position: absolute;
            top: 45px;
            width: 316px;
            height: 44px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 22px;
          }
        }
      }
    }
  }

  //用户群体
  .modular {
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-image: url("../../assets/slices/homesc_bg.png");
    text-align: center;
    background-color: #fafcff;

    .title {
      width: 164px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #1f232e;
      line-height: 22px;
    }

    .desc {
      width: 228px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #51565d;
      line-height: 17px;
    }

    .boxWrap {
      width: 364px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
      .caseBox {
        // margin: 0 50px 0 50px;
        // background: red;
        width: 182px;
        height: 190px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        p:nth-of-type(1) {
          // margin: 0;
          margin: -10px 0 12px 0;
          // width: 84px;
          // height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #33383e;
          line-height: 20px;
        }

        p:nth-of-type(2) {
          width: 178px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #51565d;
          line-height: 20px;
          margin: 0;
        }

        p:nth-of-type(3) {
          width: 178px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #51565d;
          line-height: 20px;
          margin: 0;
        }

        .iconWrap {
          // width: 194px;
          // height: 194px;
          margin: 0;

          img {
            width: 100px;
            height: 92px;
          }
        }
      }
    }
  }

  .img {
    // display: flex;
    float: left;
    // position:absolute;
    img {
      z-index: -1;
      width: 100%;
    }
  }

  .layout {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 160px;
    text-align: center;

    img {
      width: 100%;
      height: 160px;
    }

    .wordWrap {
      height: 160px;
      position: absolute;
      display: flex;
      justify-content: center;

      p {
        width: 280px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        padding-top: 44px;
      }

      .button {
        width: 80px;
        height: 29px;
        background: #fff;
        border-radius: 26px;
        position: absolute;
        border: none;
        line-height: 29px;
        padding: 0;
        margin-top: 90px;
        /deep/ span {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #4e76f7;
          line-height: 12px;
        }
      }

      .button_en {
        width: 108px;
        height: 29px;
        background: #fff;
        border-radius: 26px;
        position: absolute;

        border: none;
        line-height: 29px;
        padding: 0;
        margin-top: 90px;
        /deep/ span {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #4e76f7;
          line-height: 12px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="footerBox_m">
    <div class="f_main">
      <mu-list toggle-nested>
        <mu-list-item button :ripple="false" nested :open="defaultOpen">
          <mu-list-item-title class="mu--title">{{
            $t("PC.components.FooterC.zuk00g")
          }}</mu-list-item-title>
          <mu-list-item-action>
            <mu-icon
              class="toggle-icon"
              size="24"
              value="keyboard_arrow_down"
            ></mu-icon>
          </mu-list-item-action>
          <mu-list-item
            button
            :ripple="false"
            slot="nested"
            v-for="val of navList"
            :key="val.index"
          >
            <mu-list-item-title @mouseup="toPath(val.path)">{{
              val.name
            }}</mu-list-item-title>
          </mu-list-item>
        </mu-list-item>
      </mu-list>
      <mu-list toggle-nested>
        <mu-list-item button :ripple="false" nested :open="defaultOpen">
          <mu-list-item-title class="mu--title">{{
            $t("PC.components.FooterC.sajv7r")
          }}</mu-list-item-title>
          <mu-list-item-action>
            <mu-icon
              class="toggle-icon"
              size="24"
              value="keyboard_arrow_down"
            ></mu-icon>
          </mu-list-item-action>
          <mu-list-item button :ripple="false" slot="nested">
            <mu-list-item-title>{{
              $t("PC.components.FooterC.r2uzap")
            }}</mu-list-item-title>
          </mu-list-item>
          <mu-list-item button :ripple="false" slot="nested">
            <mu-list-item-title
              ><a href="https://www.sinoaus.net"
                >https://www.sinoaus.net</a
              ></mu-list-item-title
            >
          </mu-list-item>
          <mu-list-item button :ripple="false" slot="nested">
            <mu-list-item-title class="icon">
              <a
                href="https://map.baidu.com/search/%E5%8D%97%E4%BA%AC%E5%B8%82%E9%BC%93%E6%A5%BC%E5%8C%BA%E5%8F%A4%E5%B9%B3%E5%B2%974%E5%8F%B7%E6%99%BA%E6%A2%A6%E5%9B%ADb%E5%BA%A75%E6%A5%BC/@13220466.035,3750660.43,19z?querytype=s&da_src=shareurl&wd=%E5%8D%97%E4%BA%AC%E5%B8%82%E9%BC%93%E6%A5%BC%E5%8C%BA%E5%8F%A4%E5%B9%B3%E5%B2%974%E5%8F%B7%E6%99%BA%E6%A2%A6%E5%9B%ADb%E5%BA%A75%E6%A5%BC&c=1&src=0&pn=0&sug=0&l=5&b=(2853289.960466571,1077844.7497895127;18581929.96046657,8753748.749789514)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1"
              >
                <span class="iconfont icondiceng_dizhi"></span>
              </a>
              <span class="iconfont icondiceng_youxiang"></span
            ></mu-list-item-title>
          </mu-list-item>
        </mu-list-item>
      </mu-list>
      <!-- <p>微信关注</p>
      <img class="f_weixin" src="../../../assets/zh/images-mobile//diceng_weixin.png" alt=""> -->
      <mu-list toggle-nested>
        <mu-list-item button :ripple="false" nested :open="defaultOpen">
          <mu-list-item-title class="mu--title">{{
            $t("PC.components.FooterC.0e2d4p")
          }}</mu-list-item-title>
          <mu-list-item-action>
            <mu-icon
              class="toggle-icon"
              size="24"
              value="keyboard_arrow_down"
            ></mu-icon>
          </mu-list-item-action>
          <mu-list-item button :ripple="false" slot="nested" class="f_imfbox">
            <div class="vx">
              <div class="wx1">
                <img
                  src="../../../assets/zh/images-mobile/diceng_weixin.png"
                  alt=""
                />
                <p>{{ $t("PC.components.FooterC.0e2d15") }}</p>
              </div>

              <div class="wx2">
                <img
                  src="../../../assets/zh/images-mobile/diceng_coovally@3x.png"
                  alt=""
                />
                <p>{{ $t("PC.components.FooterC.0e2dnb") }}</p>
              </div>
            </div>
          </mu-list-item>
        </mu-list-item>
      </mu-list>
    </div>
    <div class="copyright">
      <span>© 2020 - 2023 {{ $t("PC.components.FooterC.i2fd8r") }}</span>
      <span>All Rights Reserved. Designed By Schinper.</span>
      <div class="beian">
      <img src="../../../assets/images/beian.png" alt="">
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32010602011358\" rel="\noreferrer\" target="_blank\">{{
        $t("PC.components.FooterC.ffnn5r")
      }}</a>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultOpen: false,
      navList: [
        {
          index: 0,
          name: this.$t("PC.components.FooterC.ljcr45"),
          path: "Home",
        },
        {
          index: 1,
          name: this.$t("PC.components.FooterC.yl2det"),
          path: "Product",
        },
        {
          index: 2,
          name: this.$t("PC.components.FooterC.av935f"),
          path: "Solution",
        },
        {
          index: 3,
          name: this.$t("PC.components.FooterC.hyyc12"),
          path: "Agendas",
        },
        {
          index: 4,
          name: this.$t("PC.components.FooterC.yq5vzh"),
          path: "Service",
        },
        {
          index: 5,
          name: this.$t("PC.components.FooterC.3yoxbi"),
          path: "News",
        },
        {
          index: 6,
          name: this.$t("PC.components.FooterC.z4k0gm"),
          path: "About",
        },
      ],
    };
  },
  methods: {
    toPath(path) {
      this.$router.push({
        name: path,
      });
    },
    href(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.footerBox_m {
  .f_main {
    background-color: #1a1a1a;

    /deep/ .mu-item-wrapper {
      .mu-item-title {
        font-family: PingFangSC-Regular, PingFang SC;
        color: rgba($color: #fff, $alpha: 0.6);
        font-size: 14px;
        height: 34px;
        line-height: 34px;
      }

      .mu--title {
        color: rgba($color: #fff, $alpha: 0.9);
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
      }

      .toggle-icon.mu-icon.material-icons {
        color: rgba($color: #fff, $alpha: 0.9);
      }

      .icon {
        /deep/span {
          font-size: 24px;
          color: rgba($color: #fff, $alpha: 0.9);
        }

        a {
          padding-right: 11px;
        }
      }

      .mu-list {
        height: 126px !important;

        /deep/.mu-item {
          height: 126px;
        }
      }
    }
  }

  .f_imfbox {
    height: 150px;

    .vx {
      display: flex;
      margin-left: -23px;
      margin-top: 95px;
      img {
        width: 120px;
        height: 120px;
        margin-bottom: 10px;
      }

      p {
        color: white;
      }

      .wx1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 130px;
      }
      .wx2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 130px;
        margin-left: 30px;
      }
    }
  }

  .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #252525;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba($color: #fff, $alpha: 0.6);
    line-height: 16px;
    text-align: center;
    padding: 8px 8px;
    overflow: hidden;

    span {
      display: block;
    }
    .beian{
      display: flex;
    }
    img{
      width: 16px;
      margin-right: 2px;
    }

  }
}
</style>
